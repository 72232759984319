@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
  background-color: #f0f2f5;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h3 {
  padding: 5px;
  /*border-bottom: 1px solid gray;*/
}

.bottom__line {
  /*border-bottom: 1px solid gray;*/
  margin-bottom: 3px;
}

.top__line {
  border-top: 1px solid lightslategray;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}

.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}

.title {
  font-size: 1.25rem;
  color: #050505;
  text-align: center;
  padding: 10px 0;
}

.content {
  font-size: 13px;
  padding: 10px 5px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.w-100 {
  width: 100% !important;
  height: 50vh;
  object-fit: cover;
}

.w-50 {
  width: 100% !important;
  height: 60vh;
  object-fit: cover;
}

.right__side {
  display: flex;
  justify-content: flex-end;
}

body {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
}

a {
  text-decoration: none;
  color: #212121;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  /*border-bottom: 1px solid #e6e6e6;*/
  margin-bottom: 60px;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
  color: #fbb9c5;
}

.heading p {
  color: #828282;
}

.form,
.content {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
  background-color: #ffffff;
  color: #212121;
}

/* Colors */
:root {
  --pink: #fbb9c5;
  --purple: #e6c8fe;
  --cream: #fcf7e3;
  --green: #c3edbf;
  --blue: #b8dfe6;
}

/* Form */
.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.btn {
  padding: 10px 15px;
  border: 1px solid var(--pink);
  border-radius: 5px;
  background: var(--pink);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: menulist-button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: var(--cream);
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: var(--pink) transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.author-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.sub-header {
  display: -ms-flexbox;
  width: 100%;
}

.form-icon {
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
}

.sub-card {
  display: flex;
  height: 30px;
  width: 100%;
}

.sub-text {
  display: flex;
  align-items: center;
  width: 100%;

}
.author-small-image {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.comment {
  background-color: var(--cream);
  margin: 10px 0;
  padding: 10px 0 10px;
  position: relative;
}

.comment .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
}

/* Keyframes */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media Queries */
@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }

  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}
