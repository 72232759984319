.paginationBttns {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px 15px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #15a2fa;
  color: #b8dfe6;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: #fcf7e3;
  background-color: #15a2fa;
}

.paginationActive a {
  color: #212121;
  background-color: #a3e6ff;
}

.paginationDisabled a {
  color: grey;
  background-color: #f0f2f5;
}
